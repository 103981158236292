<template>
  <b-overlay spinner-variant="primary" :show="isLoading">
    <h1 class="mb-2">
      {{ service.name }}
    </h1>
    <div class="row">
      <div class="col-12">
        <b-card title="Видима назва послуги">
          <b-form-input v-model="service.name" />
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Короткий опис послуги">
          <b-form-textarea v-model="service.description"></b-form-textarea>
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Про сервіс">
          <!-- Or manually control the data synchronization -->
          <quill-editor
            :content="service.about"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Відділ, куди направляти заявки за замовчуванням">
          <b-alert show variant="warning" class="py-1 px-1">
            <strong>УВАГА!</strong> Якщо призначити відділ за замовчуванням,
            користувач не зможе обрати інший відділ
          </b-alert>
          <v-select
            id="department-parent"
            multiple
            v-model="service.default_department"
            :options="departments"
            :reduce="(text) => text.value"
            label="text"
            placeholder="Оберіть відділ (необов'язково)"
          />
        </b-card>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="saveService">
            Зберегти зміни
          </b-button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    quillEditor,
  },
  data() {
    return {
      isLoading: false,
      service: {},
      all_departments: [],
      editorOption: {
        theme: "snow",
      },
    };
  },
  computed: {
    departments() {
      let list = this.all_departments.filter((e) => {
        return e.is_position;
      });
      list = list.map((e) => {
        return { text: e.name, value: e.id };
      });

      list.unshift({ text: "Немає", value: null });
      return list;
    },
  },
  methods: {
    async getDepartments() {
      this.isLoading = true;
      let args = {
        collectionName: "departments",
      };
      var result = await this.$db.getCollection(args);
      this.all_departments = result.docs;
      this.isLoading = false;
    },
    async getService() {
      this.isLoading = true;
      let slug = this.$route.params.slug;
      let args = {
        collectionName: "local_services",
        where: ["slug", "==", slug],
      };
      let result = await this.$db.getCollection(args);
      if (result.docs.length) {
        this.service = result.docs[0];
      }
      this.isLoading = false;
    },
    async saveService() {
      this.isLoading = true;
      let uid = this.service.uid;
      let data = Object.assign({}, this.service);
      delete data[uid];
      var result = await this.$db.update("local_services", uid, data);
      this.$toast.success("Зміни збережено");
      this.isLoading = false;
    },
    onEditorChange(e) {
      this.service.about = e.html;
    },
  },
  mounted() {
    this.getService();
    this.getDepartments();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
